<template>
    <div>
        <h1 class="heading">Восстановление</h1>

        <div class="sub-menu-links d-flex align-items-center mb-5">
            <router-link to="/login/" class="ml-0 menu-link"
            >Вход
            </router-link>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#71747A"
                class="bi bi-arrow-right ml-2"
                viewBox="0 0 16 16"
            >
                <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
            </svg>
        </div>
        <template v-if="show === true">
            <h6>Введите E-mail, чтобы получить <br/> ссылку для входа</h6>
            <div class="custom-input-group">
                <custom-input-with-label
                    :value="user.email"
                    :type="customInputEmail.type"
                    :name="customInputEmail.name"
                    :placeholder="customInputEmail.placeholder"
                    :text="customInputEmail.text"
                    v-on:change="setUserData('email', $event)"
                />
            </div>



            <main-button @click="restorePassword()">Отправить</main-button>
        </template>

        <template v-else>
            <div class="result-message">
                <font-awesome-icon icon="info-circle" class="icon alt info-icon"/>
                {{ status }}
            </div>
        </template>

        <template v-for="(error, name) in errors" :key="name">
            <template v-for="(message, idx) in error" :key="idx">
                <notify-danger class="mt-2">{{ message }}</notify-danger>
            </template>
        </template>


    </div>
</template>

<script>
export default {
    name: "Restore",
    data() {
        return {
            user: {
                email: "",
            },
            status: "",
            customInputEmail: {
                type: "email",
                name: "email",
                placeholder: "",
                text: "E-mail",
            },
            show: true,
            errors: [],
            emailRules: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //Valid Email Match
        }
    },
    methods: {
        setUserData(name, value) {

            if (value instanceof Event) return;
            this.user[name] = value;

        },
        restorePassword() {

            this.$store.dispatch('restorePassword', this.user).then((response) => {

                if (response.data.status) {

                    this.show = false
                    this.status = response.data.status

                }

            }).catch( (reason) => {

                if (reason.response.data.error) {

                    //single mistake
                    this.errors[0] = [reason.response.data.error];

                } else if (reason.response.data.errors) {

                    //multiple mistakes
                    this.errors = reason.response.data.errors;

                } else if (reason.message) {

                    if (reason.message === 'Request failed with status code 403') {

                        //Case no verification or forbidden
                        this.errors[0] = ['Доступ запрещен'];

                    } else {

                        //Any other case
                        this.errors[0] = [reason.message];

                    }
                }

            });

        },
    },
};
</script>

<style scoped>
.right-arrow-icon {
  padding-left:10px;
}
.menu-link {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #71747A;
}

.menu-link:hover {
    text-decoration: none;
    color: #3663F2;
}

.result-message {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.info-icon {
    color: #3663F2;
    font-size: 32px;
    margin-right: 10px;
}

@media (max-width: 768px) {

}
</style>
